import {combineReducers} from "redux";
// Money page will be removed when mitt-konto page is out, so this will be removed and no longer import from a private lib
// eslint-disable-next-line @nx/enforce-module-boundaries
import {WithdrawReducer, type WithdrawDomainTypes} from "@atg-payment/withdraw-domain";
import {DepositReducer} from "@atg-payment-shared/deposit-domain";
import type {DepositState} from "@atg-payment-shared/deposit-types";

export type GlobalPaymentState = {
    payment: {
        deposit: DepositState;
        withdraw: WithdrawDomainTypes.WithdrawState;
    };
};

const paymentReducer = combineReducers({
    deposit: DepositReducer,
    withdraw: WithdrawReducer,
});

export default paymentReducer;
