import {find} from "lodash";
import {createSelector} from "reselect";
import {DepositMethods} from "@atg-payment-shared/deposit-types";
import type {GlobalState} from "../domainTypes";

const getDepositState = (state: GlobalState) => state.payment.deposit;

export const statusMessage = createSelector(
    getDepositState,
    (state) => state.statusMessage,
);

export const options = createSelector(getDepositState, (state) => state.options);

export const cardOptions = createSelector(options, (state) => state.cardOptions);

export const swishDirectOption = createSelector(
    options,
    (state) => state.swishDirectOption,
);

export const existingCardOption = createSelector(
    cardOptions,
    (state) => find(state, {id: DepositMethods.existingCard}) || null,
);

export const isLoading = createSelector(getDepositState, (state) => state.isLoading);

export const isPending = createSelector(getDepositState, (state) => state.isPending);

export const isBlocked = createSelector(getDepositState, (state) => state.isBlocked);

export const selectedDepositAmount = createSelector(
    getDepositState,
    (state) => state.amount,
);
export const selectedDepositAmountAsMajor = createSelector(
    selectedDepositAmount,
    (amount) => (amount ? +amount / 100 : 0),
);
export const selectedOption = createSelector(
    getDepositState,
    (state) => state.selectedOption,
);

export const depositIframe = createSelector(
    getDepositState,
    (state) => state.depositIframe,
);

export const orderIdForDepositIframe = createSelector(depositIframe, (state) =>
    state ? state.orderId : null,
);

export const orderIdSwishDirect = createSelector(getDepositState, (state) =>
    state ? state.swishDirectOrderId : null,
);

// if deposit in iframe has been started and not finished yet
export const isDepositIframeInProgress = createSelector(
    orderIdForDepositIframe,
    isLoading,
    isPending,
    (orderId, loading, pending) => Boolean(orderId) && !loading && pending,
);

export const swishDirectStep = createSelector(
    getDepositState,
    (state) => state.swishDirectStep,
);

export const swishDirectPhoneNumber = createSelector(
    getDepositState,
    (state) => state.swishDirectPhoneNumber,
);

export const isDepositLimitsSet = createSelector(
    getDepositState,
    (state) => state.isDepositLimitsSet,
);

export const gameInfo = createSelector(getDepositState, (state) => state.gameInfo);

const getAnalyticsState = createSelector(getDepositState, (state) => state.analytics);

export const depositFlow = createSelector(
    getAnalyticsState,
    (state) => state.depositFlow,
);

export const preSelectedBtnNr = createSelector(
    getAnalyticsState,
    (state) => state.preSelectedBtnNr,
);
