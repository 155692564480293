import type {SagaIterator} from "redux-saga";
import {call, put, select} from "redux-saga/effects";
import * as Storage from "@atg-shared/storage";
import log, {serializeError} from "@atg-shared/log";
import {DepositStorageKeys, DepositStatuses} from "@atg-payment-shared/deposit-utils";
import type {AtgResponse} from "@atg-shared/fetch-types";
import * as DepositActions from "../../actions/actions";
import * as DepositApi from "../../api/api";
import {
    statusMessage,
    errorMessage,
    type DepositResponseError,
    storeUserDepositPreferences,
    logNotSuccessfulDeposit,
} from "../helpers/sagaHelpers";
import * as DepositSelectors from "../../selectors/selectors";

export function* checkDepositStatusTrustly(): SagaIterator {
    const depositOrderId: string | null = yield call(
        Storage.getItem,
        DepositStorageKeys.depositOrderId,
    );

    if (!depositOrderId) {
        return;
    }

    try {
        const orderIdAsNumber = Number(depositOrderId);
        const response = yield call(DepositApi.checkStatusTrustly, orderIdAsNumber);
        const {status} = response.data;

        if (
            status === DepositStatuses.CANCELLED ||
            status === DepositStatuses.COMPLETED
        ) {
            yield call(Storage.removeItem, DepositStorageKeys.depositWithTrustlyStarted);
            yield call(Storage.removeItem, DepositStorageKeys.isDepositFlowOnPage);
            yield call(Storage.removeItem, DepositStorageKeys.depositOrderId);
        }
        if (status === DepositStatuses.COMPLETED) {
            yield call(storeUserDepositPreferences, {
                id: "trustly",
                description: "trustly",
            });
        }
        yield call(statusMessage, status);
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        yield call(errorMessage, err.response);
    }
}

// This will replace handleRedirectFlow when iFrame solution is in place for Trustly
export function* handleIFrameFlow(): SagaIterator {
    const selectedDepositAmount: string | null = yield select(
        DepositSelectors.selectedDepositAmount,
    );
    if (!selectedDepositAmount) {
        log.error("Amount is null when trying to call handleRedirectFlow");
        return;
    }

    const amountAsNumber = Number(selectedDepositAmount);

    if (!amountAsNumber) {
        log.error(
            `Amount couldn't parse from string to number. Value: ${selectedDepositAmount}`,
        );
        return;
    }

    try {
        const response: AtgResponse<DepositApi.DepositApiResponse> = yield call(
            DepositApi.depositTrustlyIframe,
            {
                amount: amountAsNumber,
                option: {id: "trustly", description: "trustly"},
            },
        );

        if (!response) {
            log.error("No response from BE when trying to initiate a deposit");
            return;
        }

        const {hostedViewUrl, redirectUrl, orderId, amount} = response.data;

        yield put(
            DepositActions.startIframeDeposit(
                redirectUrl,
                hostedViewUrl,
                amount,
                orderId,
            ),
        );
    } catch (e: unknown) {
        const err = e as DepositResponseError;

        logNotSuccessfulDeposit(err);

        yield call(errorMessage, err.response);
    }
}

// This will be replaced with handleIFrameFlow when iFrame solution is in place for Trustly
export function* initDeposit(): SagaIterator<AtgResponse<DepositApi.DepositApiResponse> | null> {
    const amount: string | null = yield select(DepositSelectors.selectedDepositAmount);

    if (!amount) {
        log.error("Amount is null when trying to call initDeposit");
        return null;
    }

    const amountAsNumber = Number(amount);

    if (!amountAsNumber) {
        log.error(`Amount couldn't parse from string to number. Value: ${amount}`);
        return null;
    }

    try {
        yield call(Storage.setItem, DepositStorageKeys.depositWithTrustlyStarted, "true");
        const response = yield call(DepositApi.depositMoneyTrustly, amountAsNumber);
        return response;
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        log.error("Error when calling depositMoneyTrustly", {
            error: serializeError(err?.response),
        });
        yield call(errorMessage, err?.response);

        return null;
    }
}

// This will be replaced with handleIFrameFlow when iFrame solution is in place for Trustly
export function* handleRedirectFlow(): SagaIterator {
    const response: AtgResponse<DepositApi.DepositApiResponse> | null = yield call(
        initDeposit,
    );
    if (!response) return;

    const {orderId, redirectUrl} = response.data;

    yield call(Storage.setItem, DepositStorageKeys.depositOrderId, orderId.toString());

    window.location.href = redirectUrl;
}

export function* abortTrustlyDeposit(): SagaIterator {
    try {
        yield call(checkDepositStatusTrustly);
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        yield call(errorMessage, err.response);
    }
}
