import type {TrackingEvent} from "@atg-shared/analytics/analytics.types";
import type {DepositFlow} from "@atg-payment-shared/deposit-types";
import type {EvaluateSelectedPaymentReturnType} from "../saga/helpers/sagaHelpers";

type GADepositAnalyticAction =
    | "deposit_start"
    | "deposit_success"
    | "deposit_fail"
    | "deposit_abort";

type Common = {
    event: "deposit";
    action: GADepositAnalyticAction;
    amount: string;
    flow: DepositFlow;
    paymentMethod: string;
} & TrackingEvent;

export type DepositStartEvent = Common;

export type DepositSuccessEvent = {
    depositDelayed: "yes" | "no";
    slot: number | null;
    selectedPayment: EvaluateSelectedPaymentReturnType;
} & Common;

export type DepositFailEvent = {
    failReason: string;
} & Common;

export type DepositAbortEvent = Common;

export const depositStart = (
    paymentMethod: string,
    flow: DepositFlow,
    amount: string,
): DepositStartEvent => ({
    event: "deposit",
    action: "deposit_start",
    paymentMethod,
    flow,
    amount,
});

export const depositSuccess = (
    amount: string,
    isDelayed: boolean,
    paymentMethod: string,
    slot: number | null,
    selectedPayment: EvaluateSelectedPaymentReturnType,
    flow: DepositFlow,
): DepositSuccessEvent => ({
    event: "deposit",
    action: "deposit_success",
    amount,
    paymentMethod,
    depositDelayed: isDelayed ? "yes" : "no",
    slot,
    selectedPayment,
    flow,
});

export const depositFail = (
    paymentMethod: string,
    failReason: string,
    flow: DepositFlow,
    amount: string,
): DepositFailEvent => ({
    event: "deposit",
    action: "deposit_fail",
    paymentMethod,
    failReason,
    flow,
    amount,
});

export const depositAbort = (
    flow: DepositFlow,
    amount: string,
    paymentMethod: string,
): DepositAbortEvent => ({
    event: "deposit",
    action: "deposit_abort",
    flow,
    amount,
    paymentMethod,
});
