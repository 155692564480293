import {runLazySaga} from "@atg-shared/lazy-store";
import {fetchSaga} from "@atg-shared/fetch-redux";
import {kycQuestionnairePaymentSaga} from "@atg-aml-shared/kyc-domain";
import {DepositSaga, DepositAnalyticsSaga} from "@atg-payment-shared/deposit-domain";
import userSaga from "@atg-global-shared/user/userSaga";
import type {PaymentLazyStore} from "./storeTypes";

export default function rootSaga(store: PaymentLazyStore) {
    runLazySaga(store, fetchSaga);
    runLazySaga(store, kycQuestionnairePaymentSaga);
    runLazySaga(store, DepositSaga);
    runLazySaga(store, DepositAnalyticsSaga);
    runLazySaga(store, userSaga);
}
