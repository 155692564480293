import {
    DepositActionConstants,
    type DepositFlow,
    type GameInfo,
    type DepositOption,
} from "@atg-payment-shared/deposit-types";
import {type PaymentMessage} from "@atg-payment-shared/payment-status-message-types";
import {frameAction, broadcastAction} from "atg-store-addons";
import type * as Action from "./actionTypes";

export const startDepositFlow: Action.StartDepositFlow = (payload?) => ({
    type: DepositActionConstants.START_DEPOSIT_FLOW,
    payload,
});

export const startDepositFlowInModal: Action.StartDepositFlowInModal = (payload?) =>
    frameAction({
        type: DepositActionConstants.START_DEPOSIT_FLOW_IN_MODAL,
        payload,
    });

export const depositFlowFinished = (): Action.DepositFlowFinishedAction =>
    broadcastAction({
        type: DepositActionConstants.DEPOSIT_FLOW_FINISHED,
    });

export const cancelSwishDirect = (): Action.CancelSwishDirect => ({
    type: DepositActionConstants.CANCEL_SWISH_DIRECT,
});

export const depositFlowStarted: Action.DepositFlowStarted = (payload?) => ({
    type: DepositActionConstants.DEPOSIT_FLOW_STARTED,
    payload,
});

export const depositMoneySwish = (): Action.DepositMoneySwishAction => ({
    type: DepositActionConstants.DEPOSIT_MONEY_SWISH,
});

export const depositMoneyCreditCard = (): Action.DepositMoneyCreditCardAction => ({
    type: DepositActionConstants.DEPOSIT_MONEY_CREDIT_CARD,
});

export const depositMoneyTrustly = (): Action.DepositMoneyTrustlyAction => ({
    type: DepositActionConstants.DEPOSIT_MONEY_TRUSTLY,
});

export const abortTrustlyDeposit = (): Action.AbortTrustlyDepositAction => ({
    type: DepositActionConstants.ABORT_TRUSTLY_DEPOSIT,
});

export const depositStart = (): Action.DepositStartAction => ({
    type: DepositActionConstants.DEPOSIT_START,
});

export const depositFinalize = (): Action.DepositFinalizeAction => ({
    type: DepositActionConstants.DEPOSIT_FINALIZE,
});

export const depositFinalizeInIframe = (): Action.DepostiFinalizeInIframeAction => ({
    type: DepositActionConstants.DEPOSIT_FINALIZE_IFRAME,
});
export const depositSuccess: Action.DepositMoneySuccess = (amount, message, delayed) => ({
    type: DepositActionConstants.DEPOSIT_SUCCESS,
    payload: {
        amount,
        message,
        delayed,
    },
});

export const depositFailure = (
    message: PaymentMessage,
): Action.DepositMoneyFailureAction => ({
    type: DepositActionConstants.DEPOSIT_FAILURE,
    payload: {
        message,
    },
});

export const depositPending = (message: PaymentMessage): Action.DepositPendingAction => ({
    type: DepositActionConstants.DEPOSIT_PENDING,
    payload: {
        message,
    },
});

export const depositBlocked = (): Action.DepositBlockedAction => ({
    type: DepositActionConstants.DEPOSIT_BLOCKED,
});

export const depositClosed = (): Action.DepositClosedAction => ({
    type: DepositActionConstants.DEPOSIT_CLOSED,
});

export const setDepositAmount: Action.SetDepositAmount = (amount: number | string) => ({
    type: DepositActionConstants.SET_DEPOSIT_AMOUNT,
    payload: {
        selectedAmount: amount.toString(),
    },
});

export const startIframeDeposit: Action.StartIframeDeposit = (
    redirectUrl,
    hostedViewUrl,
    amount,
    orderId,
) => ({
    type: DepositActionConstants.START_IFRAME_DEPOSIT,
    payload: {
        redirectUrl,
        hostedViewUrl,
        amount,
        orderId,
    },
});

export const setSwishDirectId = (
    swishDirectOrderId: number,
): Action.SetSwishDirectOrderIdAction => ({
    type: DepositActionConstants.SET_SWISH_DIRECT_ID,
    payload: {
        swishDirectOrderId,
    },
});

export const cancelInitCardDepositInIframe =
    (): Action.CancelInitCardDepositInIframeAction => ({
        type: DepositActionConstants.CANCEL_CARD_DEPOSIT_IN_IFRAME,
    });

export const clearIframeDepositState = (): Action.ClearIframeDepositAction => ({
    type: DepositActionConstants.CLEAR_IFRAME_DEPOSIT_STATE,
});

export const popupBlocked = (message: PaymentMessage): Action.PopupBlockedAction => ({
    type: DepositActionConstants.POPUP_BLOCKED,
    payload: {
        message,
    },
});

export const clearStatusMessages = (): Action.ClearStatusMessagesAction => ({
    type: DepositActionConstants.CLEAR_STATUS_MESSAGES,
});

export const fetchOptions = (): Action.FetchOptionsAction => ({
    type: DepositActionConstants.FETCH_OPTIONS,
});

export const fetchOptionsSuccess: Action.FetchOptionsSuccess = (options) => ({
    type: DepositActionConstants.FETCH_OPTIONS_SUCCESS,
    payload: options,
});

export const fetchOptionsFailure: Action.FetchOptionsFailure = (message) => ({
    type: DepositActionConstants.FETCH_OPTIONS_FAILURE,
    payload: {message},
});

export const setSelectedOption = (payload: DepositOption) => ({
    type: DepositActionConstants.SET_SELECTED_OPTION,
    payload,
});

export const deleteBankCard = () => ({
    type: DepositActionConstants.DELETE_BANK_CARD,
});

export const deleteBankCardSuccess = (message: PaymentMessage) => ({
    type: DepositActionConstants.DELETE_BANK_CARD_SUCCESS,
    payload: {
        message,
    },
});

export const removeBankCardFailure = (message: PaymentMessage) => ({
    type: DepositActionConstants.DELETE_BANK_CARD_FAILURE,
    payload: {
        message,
    },
});

export const loadDepositState = (): Action.LoadDepositState => ({
    type: DepositActionConstants.LOAD_DEPOSIT_STATE,
});

export const resetDepositIspending = (): Action.ResetDepositIsPending => ({
    type: DepositActionConstants.RESET_DEPOSIT_IS_PENDING,
});

// it is not used anywhere
export const setSuccessfullPaymentMethodId = (
    payload: string,
): Action.SetSuccessfullDepositMethod => ({
    type: DepositActionConstants.SET_SUCCESSFULL_DEPOSIT_METHOD,
    payload,
});

export const setSwishDirectStep = (payload: string): Action.SetSwishDirectStepAction => ({
    type: DepositActionConstants.SET_SWISH_DIRECT_STEP,
    payload,
});

export const setSwishDirectPhoneNumber = (
    payload: string,
): Action.SetSwishDirectPhoneNumberAction => ({
    type: DepositActionConstants.SET_SWISH_DIRECT_PHONE_NUMBER,
    payload,
});

export const setGameInfo = (payload?: GameInfo): Action.SetGameInfoAction => ({
    type: DepositActionConstants.SET_GAME_INFO,
    payload,
});

export const setDepositFlow = (payload: DepositFlow): Action.SetDepositFlowAction => ({
    type: DepositActionConstants.SET_DEPOSIT_FLOW,
    payload,
});

export const setPreSelectedBtnNr = (
    payload: number | null,
): Action.SetPreSelectedBtnNrAction => ({
    type: DepositActionConstants.SET_PRE_SELECTED_BTN_NUMBER,
    payload,
});

export const clearDepositAnalytics = (): Action.ClearDepositAnalyticsAction => ({
    type: DepositActionConstants.CLEAR_DEPOSIT_ANALYTICS,
});

export const StartAbortObserver = (): Action.StartAbortObserverAction => ({
    type: DepositActionConstants.START_ABORT_OBSERVER,
});

export const waitForDepositLimitsToBeSet =
    (): Action.WaitForDepositLimitsToBeSetAction => ({
        type: DepositActionConstants.WAIT_FOR_DEPOSIT_LIMITS_TO_BE_SET,
    });

export const setDepositLimitsDone = (): Action.SetDepositLimitsDoneAction => ({
    type: DepositActionConstants.SET_DEPOSIT_LIMITS_DONE,
});
