import configureStore from "@atg-shared/micro-frontend/configureStore";
import {frameActionMiddleware} from "atg-store-addons";
import type {PaymentAction, PaymentLazyStore, PaymentStoreState} from "./storeTypes";
import createReducer from "./rootReducer";
import rootSaga from "./rootSaga";

declare global {
    interface Window {
        _paymentStore: PaymentLazyStore;
    }
}

const STORE_NAME = "atg-payment";

// Generally speaking, don't use this store directly – instead use Redux hooks to access the store state, etc.
const {store, filteredActionLog, persistor} = configureStore<
    PaymentStoreState,
    PaymentAction
>({
    name: STORE_NAME,
    createReducer,
    middlewares: [frameActionMiddleware],
});
rootSaga(store);

window._paymentStore = store;

export {store, filteredActionLog, persistor};
