import {combineReducers, type Reducer} from "redux";
import {filter} from "lodash";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import type {LogoutFinishedAction} from "@atg-global-shared/user/userActions";
import {DepositMethods, DepositActionConstants} from "@atg-payment-shared/deposit-types";
import type {
    CreditCardDepositOption,
    DepositOption,
    SwishDepositOption,
    TrustlyDepositOption,
    DepositFlow,
    GameInfo,
} from "@atg-payment-shared/deposit-types";
import type {
    ClearIframeDepositAction,
    SetSwishDirectOrderIdAction,
    DepositAction,
    StartIframeDepositAction,
    SetSuccessfullDepositMethod,
    SwishDirectStepAction,
    SetSwishDirectPhoneNumberAction,
    SetGameInfoAction,
    ClearGameInfoAction,
} from "../actions/actionTypes";
import type {DepositIframeState, StatusMessageState} from "../domainTypes";

const isLoading: Reducer<boolean, DepositAction> = (state = true, action) => {
    switch (action.type) {
        case DepositActionConstants.START_DEPOSIT_FLOW_IN_MODAL:
        case DepositActionConstants.FETCH_OPTIONS:
            return true;
        case DepositActionConstants.FETCH_OPTIONS_SUCCESS:
        case DepositActionConstants.FETCH_OPTIONS_FAILURE:
        case DepositActionConstants.DEPOSIT_BLOCKED:
        case DepositActionConstants.ABORT_TRUSTLY_DEPOSIT:
        case DepositActionConstants.START_IFRAME_DEPOSIT:
            return false;
        default:
            return state;
    }
};

const isPending: Reducer<boolean, DepositAction | LogoutFinishedAction> = (
    state = false,
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.DEPOSIT_START:
        case DepositActionConstants.DEPOSIT_FINALIZE:
        case DepositActionConstants.DEPOSIT_FINALIZE_IFRAME:
        case DepositActionConstants.DELETE_BANK_CARD:
        case DepositActionConstants.START_IFRAME_DEPOSIT:
            return true;
        case DepositActionConstants.DEPOSIT_SUCCESS:
        case DepositActionConstants.DEPOSIT_FAILURE:
        case DepositActionConstants.DEPOSIT_BLOCKED:
        case DepositActionConstants.DEPOSIT_PENDING:
        case DepositActionConstants.POPUP_BLOCKED:
        case DepositActionConstants.DELETE_BANK_CARD_SUCCESS:
        case DepositActionConstants.DELETE_BANK_CARD_FAILURE:
        case DepositActionConstants.DEPOSIT_CLOSED:
        case DepositActionConstants.RESET_DEPOSIT_IS_PENDING:
        case DepositActionConstants.ABORT_TRUSTLY_DEPOSIT:
        case LOGOUT_FINISHED:
            return false;

        default:
            return state;
    }
};

const isBlocked: Reducer<boolean, DepositAction> = (state = false, action) => {
    switch (action.type) {
        case DepositActionConstants.DEPOSIT_BLOCKED:
            return true;
        default:
            return state;
    }
};

const isDepositLimitsSet: Reducer<boolean, DepositAction> = (state = true, action) => {
    switch (action.type) {
        case DepositActionConstants.WAIT_FOR_DEPOSIT_LIMITS_TO_BE_SET:
            return false;
        case DepositActionConstants.SET_DEPOSIT_LIMITS_DONE:
            return true;
        default:
            return state;
    }
};

const cardOptions: Reducer<CreditCardDepositOption[] | [], DepositAction> = (
    state = [],
    action,
): CreditCardDepositOption[] => {
    switch (action.type) {
        case DepositActionConstants.FETCH_OPTIONS_SUCCESS:
            return action.payload.cards || state;
        case DepositActionConstants.FETCH_OPTIONS_FAILURE:
            return [];
        case DepositActionConstants.DELETE_BANK_CARD_SUCCESS:
            return filter(state, {id: DepositMethods.newCard});
        default:
            return state;
    }
};

const trustlyOption: Reducer<TrustlyDepositOption | null, DepositAction> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.FETCH_OPTIONS_SUCCESS: {
            const {trustly} = action.payload;

            if (!trustly) return state;

            return trustly[0];
        }
        case DepositActionConstants.FETCH_OPTIONS_FAILURE:
        default:
            return state;
    }
};

const swishDirectOption: Reducer<SwishDepositOption | null | undefined, DepositAction> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.FETCH_OPTIONS_SUCCESS: {
            const {swish} = action.payload;

            const newState = Array.isArray(swish)
                ? swish.find((option) => option.id === "swish-e-commerce") ?? state // if for some reason there will be no id with "swish-e-commerce", we return the current state
                : state;

            return newState;
        }
        case DepositActionConstants.SET_SWISH_DIRECT_PHONE_NUMBER:
            return {id: "swish-e-commerce", description: "", phoneNumber: action.payload};
        default:
            return state;
    }
};

const selectedOption: Reducer<
    DepositOption | null,
    DepositAction | LogoutFinishedAction
> = (state = null, action) => {
    switch (action.type) {
        case DepositActionConstants.SET_SELECTED_OPTION:
            return action.payload;
        case DepositActionConstants.DEPOSIT_FLOW_FINISHED:
        case LOGOUT_FINISHED:
            return null;
        default:
            return state;
    }
};

const statusMessage: Reducer<StatusMessageState | null, DepositAction> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.DEPOSIT_SUCCESS:
        case DepositActionConstants.DEPOSIT_FAILURE:
        case DepositActionConstants.DELETE_BANK_CARD_SUCCESS:
        case DepositActionConstants.DELETE_BANK_CARD_FAILURE:
        case DepositActionConstants.POPUP_BLOCKED:
        case DepositActionConstants.DEPOSIT_PENDING:
        case DepositActionConstants.FETCH_OPTIONS_FAILURE:
            return action.payload?.message;
        case DepositActionConstants.START_DEPOSIT_FLOW_IN_MODAL:
        case DepositActionConstants.DEPOSIT_FLOW_STARTED:
        case DepositActionConstants.CLEAR_STATUS_MESSAGES:
            return null;
        default:
            return state;
    }
};

const amount: Reducer<string, DepositAction | LogoutFinishedAction> = (
    state = "",
    action,
) => {
    const {type} = action;

    switch (type) {
        case DepositActionConstants.SET_DEPOSIT_AMOUNT:
            return action.payload?.selectedAmount?.toString() || state;
        case DepositActionConstants.DEPOSIT_FLOW_FINISHED:
        case LOGOUT_FINISHED:
            return "";
        default:
            return state;
    }
};

const depositIframe: Reducer<
    DepositIframeState | null,
    StartIframeDepositAction | LogoutFinishedAction | ClearIframeDepositAction
> = (state = null, action) => {
    switch (action.type) {
        case DepositActionConstants.START_IFRAME_DEPOSIT:
            return action.payload;
        case LOGOUT_FINISHED:
        case DepositActionConstants.CLEAR_IFRAME_DEPOSIT_STATE:
            return null;
        default:
            return state;
    }
};

const successfullPaymentMethodId: Reducer<string, SetSuccessfullDepositMethod> = (
    state = "",
    action,
) => {
    if (action.type === DepositActionConstants.SET_SUCCESSFULL_DEPOSIT_METHOD) {
        return action.payload;
    }
    return state;
};

const swishDirectStep: Reducer<string, SwishDirectStepAction | DepositAction> = (
    state = "",
    action,
) => {
    if (action.type === DepositActionConstants.SET_SWISH_DIRECT_STEP) {
        return action.payload;
    }
    return state;
};

const swishDirectPhoneNumber: Reducer<string, SetSwishDirectPhoneNumberAction> = (
    state = "",
    action,
) => {
    if (action.type === DepositActionConstants.SET_SWISH_DIRECT_PHONE_NUMBER) {
        return action.payload;
    }
    return state;
};

const swishDirectOrderId: Reducer<number | null, SetSwishDirectOrderIdAction> = (
    state = null,
    action,
) => {
    if (action.type === DepositActionConstants.SET_SWISH_DIRECT_ID) {
        return action.payload.swishDirectOrderId;
    }
    return state;
};

const gameInfo: Reducer<GameInfo | null, SetGameInfoAction | ClearGameInfoAction> = (
    state = {},
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.SET_GAME_INFO:
            if (!action.payload) return {};
            return action.payload;
        case DepositActionConstants.CLEAR_GAME_INFO:
            return {};
        default:
            return state;
    }
};

const preSelectedBtnNr: Reducer<number | null, DepositAction> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case DepositActionConstants.SET_PRE_SELECTED_BTN_NUMBER:
            return action.payload;
        case DepositActionConstants.CLEAR_DEPOSIT_ANALYTICS:
            return null;
        default:
            return state;
    }
};

const depositFlow: Reducer<DepositFlow, DepositAction> = (state = "", action) => {
    switch (action.type) {
        case DepositActionConstants.SET_DEPOSIT_FLOW:
            return action.payload;
        case DepositActionConstants.CLEAR_DEPOSIT_ANALYTICS:
            return "";
        default:
            return state;
    }
};

const options = combineReducers({
    cardOptions,
    swishDirectOption,
    trustlyOption,
});

const analytics = combineReducers({
    depositFlow,
    preSelectedBtnNr,
});

const depositReducer = combineReducers({
    isLoading,
    isPending,
    isBlocked,
    isDepositLimitsSet,
    options,
    selectedOption,
    statusMessage,
    amount,
    depositIframe,
    successfullPaymentMethodId,
    swishDirectStep,
    swishDirectPhoneNumber,
    swishDirectOrderId,
    gameInfo,
    analytics,
});

export default depositReducer;
