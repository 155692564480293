import {call, put, select, delay} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {DepositStatuses} from "@atg-payment-shared/deposit-utils";
import log from "@atg-shared/log";
import type {CreditCardDepositOption} from "@atg-payment-shared/deposit-types";
import * as DepositActions from "../../actions/actions";
import * as DepositSelectors from "../../selectors/selectors";
import * as DepositApi from "../../api/api";
import {
    statusMessage,
    errorMessage,
    POLL_TIMEOUT_TICK,
    type DepositResponseError,
    logNotSuccessfulDeposit,
} from "../helpers/sagaHelpers";

export function* handleIFrameFlow(): SagaIterator {
    const selectedAmount = yield select(DepositSelectors.selectedDepositAmount);

    const selectedAmountAsNumber = Number(selectedAmount);

    if (!selectedAmountAsNumber) {
        log.error(
            `handleIFrameFlow saga called when selectedAmount can not be parsed to number: ${selectedAmount}`,
        );
        yield call(statusMessage, DepositStatuses.ERROR);
        return;
    }

    const option: CreditCardDepositOption = yield select(DepositSelectors.selectedOption);

    if (option.id !== "newCard" && option.id !== "existingCard") {
        log.error("handleIFrameFlow saga called when selectedOption is not Credit Card");
        yield call(statusMessage, DepositStatuses.ERROR);
        return;
    }

    try {
        const response = yield call(DepositApi.depositApiCall, {
            amount: selectedAmountAsNumber,
            option,
            storeCard: option.storeCard,
        });

        if (!response) {
            log.error("No response from BE when trying to initiate a deposit");
            return;
        }

        const {redirectUrl, hostedViewUrl, orderId, amount} = response.data;
        yield put(
            DepositActions.startIframeDeposit(
                redirectUrl,
                hostedViewUrl,
                amount,
                orderId,
            ),
        );
    } catch (e: unknown) {
        const err = e as DepositResponseError;

        logNotSuccessfulDeposit(err);

        yield call(errorMessage, err.response);
    }
}

export function* finalizeDepositIframe(): SagaIterator {
    try {
        const orderId = yield select(DepositSelectors.orderIdForDepositIframe);
        const maxPollTime = 3000;
        let timeSpent = 0;
        let response = yield call(DepositApi.checkStatusIframe, orderId);

        let {status} = response.data;

        while (status === DepositStatuses.IN_PROGRESS && timeSpent < maxPollTime) {
            yield delay(POLL_TIMEOUT_TICK);
            timeSpent += POLL_TIMEOUT_TICK;

            try {
                response = yield call(DepositApi.checkStatusIframe, orderId);
                ({status} = response.data);
            } catch (error: unknown) {
                const err = error as DepositResponseError;
                yield call(errorMessage, err.response);
            }
        }

        /**
         * payex is not always reliable and may return status "finished" too early,
         * even if the deposit has not been completed in our BE.
         * We poll the status to our BE until it is finished for real.
         *
         * If we exceed the max time, we cancel the deposit and emulate a
         * timeout failure. (Since we don't get a real timeout from BE)
         *
         * Note that this finalize function runs AFTER the payex iframe is
         * finished which means that this function runs on the initial deposit
         * screen. This may cause confusion both for us and our users.
         */
        if (status === DepositStatuses.IN_PROGRESS && timeSpent >= maxPollTime) {
            yield call(statusMessage, DepositStatuses.DELAYED);
        } else {
            yield put(DepositActions.depositFinalize());
            yield call(statusMessage, status);
        }

        yield put(DepositActions.clearIframeDepositState());
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        yield call(errorMessage, err.response);
    }
}
