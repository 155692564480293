import queryString from "query-string";
import {now} from "lodash";
import {fetchAuthorized} from "@atg-shared/auth";
import {browser} from "@atg/utils";
import log from "@atg-shared/log";
import {
    DepositURLs,
    type DepositResponseStatusType,
} from "@atg-payment-shared/deposit-utils";
import type {
    DepositMethod,
    SwishDepositOption,
    TrustlyDepositOption,
    CreditCardDepositOption,
    DepositOption,
} from "@atg-payment-shared/deposit-types";
import {DepositMethods} from "@atg-payment-shared/deposit-types";
import type {DepositOptionsResponse} from "../domainTypes";

export const getEndpoint = (option: DepositMethod): string => {
    switch (option) {
        case DepositMethods.existingCard:
        case DepositMethods.newCard:
            return DepositURLs.CREDIT_CARD_URL;
        case DepositMethods["swish-e-commerce"]:
            return DepositURLs.SWISH_DIRECT_URL;
        case DepositMethods.trustly:
            return DepositURLs.TRUSTLY_URL;
        default:
            log.error(`depositApiCall called with unknown option: ${option}`);
            return "";
    }
};

export type DepositMoneyProps = {
    amount: number;
    option: DepositOption;
    noCvc?: boolean;
    ssnValidation?: boolean;
    openInNewWindow?: boolean;
    mobilePhone?: string;
    phoneNumber?: string;
};

type DepositApiCallProps = {
    amount: number;
    option: SwishDepositOption | CreditCardDepositOption | TrustlyDepositOption;
    storeCard?: boolean;
};

export type DepositApiResponse = {
    orderId: number;
    amount: number;
    status: DepositResponseStatusType;
    redirectUrl: string;
    hostedViewUrl: string;
    paymentId: string;
};

export const depositApiCall = ({amount, option, storeCard}: DepositApiCallProps) =>
    fetchAuthorized<DepositApiResponse>(getEndpoint(option.id), {
        method: "POST",
        headers: {
            token: now().toString(),
        },
        body: JSON.stringify({
            amount,
            option,
            noCvc: true,
            storeCard,
            completeUrl: "https://atg.se",
            cancelUrl: "https://atg.se",
            ssnValidation: false,
        }),
    });

type DepositSwishProps = {
    amount: number;
    option: SwishDepositOption;
};

export const depositSwishIframe = ({amount, option}: DepositSwishProps) =>
    depositApiCall({amount, option});

type DepositCreditCardProps = {
    amount: number;
    option: CreditCardDepositOption;
};

export const depositCreditCardIframe = ({amount, option}: DepositCreditCardProps) =>
    depositApiCall({amount, option, storeCard: option.storeCard});

type DepositTrustlyProps = {
    amount: number;
    option: TrustlyDepositOption;
};

export const depositTrustlyIframe = ({amount, option}: DepositTrustlyProps) =>
    depositApiCall({amount, option});

type Params = {
    orderRef?: string;
    newWindow?: boolean;
};

const getUrl = (url: string, params?: Params) =>
    url + (params ? `?${queryString.stringify(params)}` : "");

// Function is used only for deposit flow via Trustly
// This will be removed when v2/deposit/trustly is live and will be in iFrame instead of redirect
export const depositMoneyTrustly = (amount: number) => {
    const isApp = window.requestedBy === "ATG-Live-App";
    const origin = browser.getOrigin();
    const currentUrl = origin + window.location.pathname;
    const currentParams = isApp ? undefined : queryString.parse(window.location.search);

    const option: TrustlyDepositOption = {
        id: "trustly",
        description: "trustly",
    };

    const completeUrl = getUrl(currentUrl, currentParams);

    const cancelUrl = getUrl(currentUrl, currentParams);

    const endpoint = DepositURLs.TRUSTLY_URL;

    return fetchAuthorized<DepositApiResponse>(endpoint, {
        method: "POST",
        headers: {
            token: now().toString(),
        },
        body: JSON.stringify({
            completeUrl,
            cancelUrl,
            amount,
            option,
        }),
    });
};

export const deleteStoredBankCard = () =>
    fetchAuthorized(`${DepositURLs.OPTIONS_URL}/creditcard`, {
        method: "DELETE",
    });

export type CheckStatusIframeResponse = {
    orderId: number;
    amount: number;
    status: DepositResponseStatusType;
    redirectUrl: string;
    hostedViewUrl: string;
    paymentId: string;
};

export const checkStatusSwishDirect = (orderId: number) =>
    fetchAuthorized<CheckStatusIframeResponse>(
        `${DepositURLs.SWISH_DIRECT_URL}/${orderId}`,
        {method: "GET"},
    );

export const checkStatusTrustly = (orderId: number) =>
    fetchAuthorized<CheckStatusIframeResponse>(`${DepositURLs.TRUSTLY_URL}/${orderId}`, {
        method: "GET",
    });

export const checkStatusIframe = (orderId: string) =>
    fetchAuthorized<CheckStatusIframeResponse>(
        `${DepositURLs.CREDIT_CARD_URL}/${orderId}`,
        {
            method: "GET",
        },
    );

export const cancelInitiatedDepositInIframe = (orderId: string) =>
    fetchAuthorized(`${DepositURLs.CREDIT_CARD_URL}/${orderId}`, {
        method: "DELETE",
    });

export const cancelSwishDirect = (orderId: number) =>
    fetchAuthorized(`${DepositURLs.SWISH_DIRECT_URL}/${orderId}`, {
        method: "DELETE",
    });

export const fetchOptionsIframe = () =>
    fetchAuthorized<DepositOptionsResponse>(DepositURLs.OPTIONS_URL);
