import {call, put, select, delay} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import log, {serializeError} from "@atg-shared/log";
import {DepositStatuses} from "@atg-payment-shared/deposit-utils";
import type {SwishDepositOption} from "@atg-payment-shared/deposit-types";
import * as DepositActions from "../../actions/actions";
import * as DepositSelectors from "../../selectors/selectors";
import * as DepositApi from "../../api/api";
import {
    statusMessage,
    POLL_TIMEOUT_TICK,
    type DepositResponseError,
    errorMessage,
    logNotSuccessfulDeposit,
} from "../helpers/sagaHelpers";
import type {DepositStatusTypes} from "../../domainTypes";
import {depositSwishIframe} from "../../api/api";

export function* checkSwishDirectDepositStatus(orderId: number): SagaIterator {
    const pollTimeout = 180 * 1000; // 3 minutes
    const waitUntilFirstCheck = 10_000; // 10 sec in ms

    // We do a initial waiting for 10 seconds, therefor timeSpent and nextCheck starts at 10 seconds
    let timeSpent = waitUntilFirstCheck;
    let nextCheck = waitUntilFirstCheck;
    let status: DepositStatusTypes = DepositStatuses.IN_PROGRESS;

    yield delay(waitUntilFirstCheck);

    while (status === DepositStatuses.IN_PROGRESS && timeSpent <= pollTimeout) {
        try {
            if (timeSpent === nextCheck) {
                const response = yield call(DepositApi.checkStatusSwishDirect, orderId);
                status = response.data.status;
                nextCheck = timeSpent + 3000;
            }
        } finally {
            timeSpent += POLL_TIMEOUT_TICK;
            yield delay(POLL_TIMEOUT_TICK);
        }
    }

    yield put(DepositActions.depositFinalize());
    yield call(statusMessage, status);
    yield put(DepositActions.resetDepositIspending());
    yield put(DepositActions.setSwishDirectStep(DepositStatuses.SWISH_DIRECT_RESET));
}

export function* cancelSwishDirect(): SagaIterator {
    try {
        const swishDirectOrderId: number | null = yield select(
            DepositSelectors.orderIdSwishDirect,
        );
        if (swishDirectOrderId) {
            yield call(DepositApi.cancelSwishDirect, swishDirectOrderId);
        }
        yield put(DepositActions.clearIframeDepositState());
        yield put(DepositActions.resetDepositIspending());
    } catch (e: unknown) {
        const err = e as DepositResponseError;
        log.error("cancelSwishDirectError: ", {error: serializeError(err)});
    }
}

export function* handleSwishDirectFlow(): SagaIterator {
    const amount = yield select(DepositSelectors.selectedDepositAmount);
    const option: SwishDepositOption = yield select(DepositSelectors.swishDirectOption);
    const payload = {
        amount,
        option,
    };

    try {
        const response = yield call(depositSwishIframe, payload);

        if (!response) {
            log.error("No response from BE when trying to initiate a deposit");
            return;
        }

        const {orderId} = response.data;

        yield put(DepositActions.setSwishDirectId(orderId));
        yield call(checkSwishDirectDepositStatus, orderId);
    } catch (e: unknown) {
        const err = e as DepositResponseError;

        logNotSuccessfulDeposit(err);

        yield call(errorMessage, err.response);
    }
}
